exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-banner-tsx": () => import("./../../../src/pages/about/Banner.tsx" /* webpackChunkName: "component---src-pages-about-banner-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blog-a-complete-guide-to-create-an-ecommerce-app-like-amazon-tsx": () => import("./../../../src/pages/blog/a-complete-guide-to-create-an-ecommerce-app-like-amazon.tsx" /* webpackChunkName: "component---src-pages-blog-a-complete-guide-to-create-an-ecommerce-app-like-amazon-tsx" */),
  "component---src-pages-blog-banner-jsx": () => import("./../../../src/pages/blog/Banner.jsx" /* webpackChunkName: "component---src-pages-blog-banner-jsx" */),
  "component---src-pages-blog-how-to-select-the-right-company-to-join-tsx": () => import("./../../../src/pages/blog/how-to-select-the-right-company-to-join.tsx" /* webpackChunkName: "component---src-pages-blog-how-to-select-the-right-company-to-join-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mobile-app-ideas-to-fuel-different-business-industries-tsx": () => import("./../../../src/pages/blog/mobile-app-ideas-to-fuel-different-business-industries.tsx" /* webpackChunkName: "component---src-pages-blog-mobile-app-ideas-to-fuel-different-business-industries-tsx" */),
  "component---src-pages-blog-the-evolution-of-iot-application-and-benefits-tsx": () => import("./../../../src/pages/blog/the-evolution-of-iot-application-and-benefits.tsx" /* webpackChunkName: "component---src-pages-blog-the-evolution-of-iot-application-and-benefits-tsx" */),
  "component---src-pages-blog-top-10-technology-trends-2019-tsx": () => import("./../../../src/pages/blog/top-10-technology-trends-2019.tsx" /* webpackChunkName: "component---src-pages-blog-top-10-technology-trends-2019-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-careers-banner-tsx": () => import("./../../../src/pages/careers/Banner.tsx" /* webpackChunkName: "component---src-pages-careers-banner-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-contact-us-banner-jsx": () => import("./../../../src/pages/contact-us/Banner.jsx" /* webpackChunkName: "component---src-pages-contact-us-banner-jsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-gallery-banner-tsx": () => import("./../../../src/pages/gallery/Banner.tsx" /* webpackChunkName: "component---src-pages-gallery-banner-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("./../../../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-gallery-slider-jsx": () => import("./../../../src/pages/gallery/Slider.jsx" /* webpackChunkName: "component---src-pages-gallery-slider-jsx" */),
  "component---src-pages-gallery-sports-jsx": () => import("./../../../src/pages/gallery/Sports.jsx" /* webpackChunkName: "component---src-pages-gallery-sports-jsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-leaders-on-the-ground-index-tsx": () => import("./../../../src/pages/leaders-on-the-ground/index.tsx" /* webpackChunkName: "component---src-pages-leaders-on-the-ground-index-tsx" */),
  "component---src-pages-portfolio-banner-jsx": () => import("./../../../src/pages/portfolio/Banner.jsx" /* webpackChunkName: "component---src-pages-portfolio-banner-jsx" */),
  "component---src-pages-portfolio-graphic-design-tsx": () => import("./../../../src/pages/portfolio/graphic-design.tsx" /* webpackChunkName: "component---src-pages-portfolio-graphic-design-tsx" */),
  "component---src-pages-portfolio-mobile-app-development-tsx": () => import("./../../../src/pages/portfolio/mobile-app-development.tsx" /* webpackChunkName: "component---src-pages-portfolio-mobile-app-development-tsx" */),
  "component---src-pages-portfolio-project-[name]-tsx": () => import("./../../../src/pages/portfolio/project/[name].tsx" /* webpackChunkName: "component---src-pages-portfolio-project-[name]-tsx" */),
  "component---src-pages-portfolio-web-development-tsx": () => import("./../../../src/pages/portfolio/web-development.tsx" /* webpackChunkName: "component---src-pages-portfolio-web-development-tsx" */),
  "component---src-pages-services-banner-jsx": () => import("./../../../src/pages/services/Banner.jsx" /* webpackChunkName: "component---src-pages-services-banner-jsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-sitemap-banner-tsx": () => import("./../../../src/pages/sitemap-banner.tsx" /* webpackChunkName: "component---src-pages-sitemap-banner-tsx" */),
  "component---src-pages-sitemap-index-xml-tsx": () => import("./../../../src/pages/sitemap-index.xml.tsx" /* webpackChunkName: "component---src-pages-sitemap-index-xml-tsx" */),
  "component---src-pages-trip-to-hail-himalayas-index-tsx": () => import("./../../../src/pages/trip-to-hail-himalayas/index.tsx" /* webpackChunkName: "component---src-pages-trip-to-hail-himalayas-index-tsx" */)
}

